import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { BgImage } from "gbimage-bridge";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3.1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const OurServices = ({
	heading,
	tagline,
	subtitle,
	content,
	ctaButton,
	bgImage,
	services,
}) => {
	return (
		<BgImage
			image={getImage(bgImage)}
			className="py-5 py-md-8 position-relative"
		>
			<div
				style={{
					zIndex: 1,
					background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%)`,
				}}
				className="position-absolute w-100 h-100 top-0 start-0"
			></div>
			<div style={{ zIndex: 2 }} className="position-relative">
				<div className="px-72">
					<Row className="align-items-lg-center">
						<Col lg={7} className="mb-4 mb-lg-0 text-start">
							<span className="text-secondary">{tagline}</span>
							<h2 className="my-auto text-white pb-2">{heading}</h2>
							<h6 className="text-white mb-4">{subtitle}</h6>
							<Button
								as={Link}
								href={ctaButton.url}
								size="lg"
								className="px-4 py-3 montserrat-bold fs-6"
								target={ctaButton.target}
								variant="secondary"
							>
								{ctaButton.title}
							</Button>
						</Col>
						<Col lg={5} className="mb-4 mb-lg-0 text-end">
							<div
								dangerouslySetInnerHTML={{
									__html: content,
								}}
								className="fw-light text-white text-start"
							></div>
						</Col>
					</Row>
				</div>
				<div className="pt-5 pl-72">
					<Carousel
						swipeable={false}
						draggable={false}
						showDots={false}
						responsive={responsive}
						infinite={true}
						arrows={true}
						className="pt-md-7 pb-5 pb-md-0"
					>
						{services.map((item, i) => (
							<div className="mx-3 ms-lg-0 me-lg-4" key={i}>
								<h4 className="text-white fw-black pb-4 text-center montserrat-black mb-0 fs-5">
									{item.title}
								</h4>
								<Link
									to={item.servicesPostFields?.serviceCta?.url}
									className="d-block"
								>
									<GatsbyImage
										image={
											item.servicesPostFields?.serviceImage.node?.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={item.servicesPostFields?.serviceImage.node?.altText}
										width="100%"
										className="border border-white border-2 rounded-5"
										style={{ height: "354px" }}
									/>
								</Link>
								<div
									dangerouslySetInnerHTML={{
										__html: item.servicesPostFields?.serviceContent,
									}}
									className="fs-6 pt-3 fw-medium text-white "
								></div>
							</div>
						))}
					</Carousel>
				</div>
			</div>
		</BgImage>
	);
};

export const sectionWrapper = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

export default OurServices;
